import firebase from "firebase";
import "firebase/auth";

/* Put here your own firebase config */
const app = firebase.initializeApp({
    apiKey: "AIzaSyC8oLfp3Q-_RX6qhXluWBePYb4Sh81boSs",
    authDomain: "aichat-f920f.firebaseapp.com",
    projectId: "aichat-f920f",
    storageBucket: "aichat-f920f.appspot.com",
    messagingSenderId: "484535020113",
    appId: "1:484535020113:web:6f89dd6f3992004fdf2670"
});

const db = firebase.firestore();
const rdb = firebase.database();
const sdb = firebase.storage();
const auth = app.auth()

export {db, auth, rdb, sdb}
export default app
import React from 'react'
import "./Rights.css"

const Rights = () => {
    return (
        <div id="rights" >
            <p>© Messenger by Fistrba 2021. All rights reserved.</p>
        </div>
    )
}

export default Rights

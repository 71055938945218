import React from 'react'
import ilus from "../../../../assets/auth/ilus-bg.svg"
import "./IlusForm.css"

const IlusForm = () => {
    return (
        <div className="ilus" >
            <img src={ilus} className="ilus" alt="ilus" />
        </div>
    )
}

export default IlusForm
